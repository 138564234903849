<template>
  <div class="member-shop-point-exchange-center">
    <PageTitle title="點數兌換中心" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="輸入商品名稱"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-select
        v-model="search.type"
        clearable
        placeholder="選擇商品性質"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="type in productTypeConfig"
          :key="type.value"
          :label="type.label"
          :value="type.value"
        />
      </el-select>
    </FiltersContainer>
    <MemberShopPointExchangeTable v-loading="loading.table" :tableData="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import MemberShopPointExchangeTable from './components/MemberShopPointExchangeTable.vue'
import { GetMemberShopProduct, GetMemberShopProductCount } from '@/api/memberShop'
import { productTypeConfig } from '@/config/memberShop'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'MemberShopPointExchangeCenter',
  components: { PageTitle, MemberShopPointExchangeTable, FiltersContainer },
  setup (props) {
    const router = useRouter()
    const { shopId, loading, tableData, tableDataCount, tableOptions, pageStartIndex, search, extendData, fetchData, fetchDataCount } = useTable()
    extendData('search', {
      name: null,
      type: null,
    })
    const onCreate = () => {
      router.push({ name: 'MemberShopProductExchangeEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        type: search.type || undefined,
        isPointExchange: true,
      }
      await Promise.allSettled([
        fetchData(GetMemberShopProduct, payload),
        fetchDataCount(GetMemberShopProductCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      refresh()
    })

    return { onCreate, search, tableDataCount, tableOptions, tableData, refresh, loading, productTypeConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
